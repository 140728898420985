import { computed, ComputedRef, ref, Ref } from 'vue';

import { CompanyApi, ProductApi } from '@/api';
import { CreateProductForm, ChangeProductForm } from '@/api/openapi';

import useLoading from '@/composables/loading';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { VALIDATION_MESSAGE } from '@/common/validation';

interface GroupSetting {
  isPosting: Ref<boolean>;
  isPutting: Ref<boolean>;
  groupName: Ref<string>;
  post: (props) => Promise<boolean>;
  put: (props) => Promise<boolean>;
}

export const useGroupSetting = (): GroupSetting => {
  const groupName = ref('');

  const [isPosting, post] = useLoading(_post);
  const [isPutting, put] = useLoading(_put);

  return {
    isPosting,
    isPutting,
    groupName,
    post,
    put
  };
};

const _post = async (props: {
  companyId: number;
  form: CreateProductForm;
}): Promise<boolean> => {
  try {
    await CompanyApi.postCompaniesCompanyIdProducts(
      props.companyId,
      props.form
    );
    toast({
      title: '成功',
      message: '商品を名寄せしました',
      variant: 'success'
    });
    return true;
  } catch (e) {
    if (e.status === httpCode.conflict) {
      toast({
        title: '失敗',
        message: e.message,
        variant: 'error'
      });
    } else {
      handleError(e);
    }
    return false;
  }
};

const _put = async (props: {
  productId: number;
  form: ChangeProductForm;
}): Promise<boolean> => {
  try {
    await ProductApi.putProductsProductId(props.productId, props.form);
    toast({
      title: '成功',
      message: '商品を名寄せしました',
      variant: 'success'
    });
    return true;
  } catch (e) {
    handleError(e);
    return false;
  }
};

type ValidateReturnType = string | undefined;

interface UseValidate {
  validateProductName: ComputedRef<ValidateReturnType>;
}

export const useValidate = (productName: Ref<string>): UseValidate => {
  const PRODUCT_NAME_MAX_LENGTH = 256;

  const _validateProductName = () => {
    if (productName.value.trim().length === 0) {
      return VALIDATION_MESSAGE.required;
    } else if (productName.value.length > PRODUCT_NAME_MAX_LENGTH) {
      return VALIDATION_MESSAGE.maxN(PRODUCT_NAME_MAX_LENGTH);
    } else {
      return undefined;
    }
  };

  const validateProductName = computed(() => {
    return _validateProductName();
  });

  return {
    validateProductName
  };
};
