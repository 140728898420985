import { CompanyApi, CustomTargetApi, DefaultApi } from '@/api';
import { CustomTarget, Products } from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import useModal from '@/composables/modal';
import { SETTINGS_ROUTES } from '@/router';
import { useBrandStore } from '@/store/brand';
import { format, subDays } from 'date-fns';
import { Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

type PRODUCTS_ORDER = '直近CM放送日' | '最終更新日';

export const ORDERS = {
  CM_CREATIVE_DATE: '直近CM放送日',
  UPDATED_AT: '最終更新日'
} as const;

const convertOrder = (order: PRODUCTS_ORDER) => {
  switch (order) {
    case ORDERS.CM_CREATIVE_DATE:
      return 'CM_CREATIVE_DATE';
    case ORDERS.UPDATED_AT:
      return 'UPDATED_AT';
  }
};

interface UseCompanyProductsList {
  // 商品一覧
  companyProductsList: Ref<Products[]>;
  startDate: Ref<Date | null>;
  endDate: Ref<Date | null>;
  order: Ref<PRODUCTS_ORDER>;
  searchText: Ref<string>;
  isLoadingCompanyProductList: Ref<boolean>;
  getCompanyProductsList: (params: {
    companyId: number;
    queryStartDate?: string;
    queryEndDate?: string;
  }) => Promise<void>;
  // カスタムターゲット
  customTargets: Ref<CustomTarget[]>;
  getCustomTargets: (companyId: number) => Promise<void>;
  // 名寄せモーダル
  isModalOpen: Ref<boolean>;
  openModal: () => void;
  closeModal: () => void;
  isGroupSettingModalOpen: Ref<boolean>;
  openGroupSettingModal: () => void;
  closeGroupSettingModal: () => void;
  // 商品の初期化
  isRemoving: Ref<boolean>;
  resetProduct: (params: {
    productId: number;
    affiliatedCompanyId: number;
  }) => void;
}

export const useCompanyProductsList = (): UseCompanyProductsList => {
  const router = useRouter();
  const brandStore = useBrandStore();
  const companyProductsList = ref<Products[]>([]);
  const searchText = ref('');
  const today = new Date();
  const startDate = ref<Date | null>(subDays(today, 90));
  const endDate = ref<Date | null>(today);
  const order = ref<PRODUCTS_ORDER>('直近CM放送日');
  const customTargets = ref<CustomTarget[]>([]);

  const _getCompanyProductsList = async ({
    companyId,
    queryStartDate,
    queryEndDate
  }: {
    companyId: number;
    queryStartDate?: string;
    queryEndDate?: string;
  }) => {
    const startDateParam =
      queryStartDate ||
      (startDate.value ? format(startDate.value, DATE_FORMAT) : undefined);

    const endDateParam =
      queryEndDate ||
      (endDate.value ? format(endDate.value, DATE_FORMAT) : undefined);

    if (startDateParam && endDateParam) {
      const { data } = await CompanyApi.getCompaniesCompanyIdProductsSearch(
        companyId,
        startDateParam,
        endDateParam,
        convertOrder(order.value),
        undefined,
        undefined,
        searchText.value !== '' ? searchText.value : undefined
      );
      companyProductsList.value = data.list ?? [];
    } else {
      companyProductsList.value = [];
    }
  };

  const [isLoadingCompanyProductList, getCompanyProductsList] = useLoading(
    _getCompanyProductsList
  );

  const getCustomTargets = async (companyId: number) => {
    const {
      data: { list }
    } = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(companyId);
    if (list) {
      customTargets.value = list;
    }
  };

  const { isModalOpen, openModal, closeModal } = useModal(false);
  const {
    isModalOpen: isGroupSettingModalOpen,
    openModal: openGroupSettingModal,
    closeModal: closeGroupSettingModal
  } = useModal(false);

  // 初期化
  const _resetProduct = async (props: {
    productId: number;
    affiliatedCompanyId: number;
  }) => {
    try {
      const res = await DefaultApi.deleteProductsProductId(props.productId);
      const statusCode = res.status;
      if (200 <= statusCode && statusCode < 300) {
        closeModal();
        toast({
          title: '完了',
          message: '初期化しました',
          variant: 'success'
        });
        brandStore.selectCmSponsor(Number(props.affiliatedCompanyId));
        await router.push({
          name: SETTINGS_ROUTES.brand
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      closeModal();
      const message = ref('初期化に失敗しました');
      if (e.status === httpCode.conflict) {
        message.value = e.message ?? '初期化に失敗しました';
      }
      toast({
        title: '失敗',
        message: message.value,
        variant: 'error'
      });
    }
  };

  const [isRemoving, resetProduct] = useLoading(_resetProduct);

  return {
    companyProductsList,
    customTargets,
    endDate,
    order,
    searchText,
    startDate,
    isLoadingCompanyProductList,
    getCompanyProductsList,
    getCustomTargets,
    isModalOpen,
    isRemoving,
    openModal,
    closeModal,
    isGroupSettingModalOpen,
    openGroupSettingModal,
    closeGroupSettingModal,
    resetProduct
  };
};
