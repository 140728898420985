<template>
  <BaseCheckbox
    :checked="isChecked"
    :disabled="disabled"
    :label="label"
    :block="block"
    :buttonHeight="buttonHeight"
  >
    <template v-slot:left>
      <slot name="left" />
    </template>
    <input
      v-model="checked"
      type="checkbox"
      :value="value"
      :disabled="disabled"
      @change="onChange"
    />
  </BaseCheckbox>
</template>
<script>
import { defineComponent, computed } from 'vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';

export default defineComponent({
  props: {
    modelValue: [Boolean, Array],
    value: [String, Number, Object],
    label: String,
    disabled: Boolean,
    block: Boolean,
    buttonHeight: String,
    readonly: Boolean
  },
  components: { BaseCheckbox },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const checked = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    return {
      checked,
      isChecked: computed(() =>
        Array.isArray(checked.value)
          ? checked.value.some(
              val => JSON.stringify(val) === JSON.stringify(props.value)
            )
          : JSON.stringify(checked.value) === JSON.stringify(props.value)
      ),
      onChange: () => emit('change', checked.value)
    };
  }
});
</script>
