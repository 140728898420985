import { defineStore } from 'pinia';

export const useBrandStore = defineStore('brand', {
  state: () => ({
    selectedCmSponsorId: null as number | null
  }),
  actions: {
    selectCmSponsor(cmSponsorId: number | null) {
      this.selectedCmSponsorId = cmSponsorId;
    }
  }
});
