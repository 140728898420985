import { CompanyApi } from '@/api';
import { OtherCmProduct } from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { format } from 'date-fns';
import { computed, Ref, ref } from 'vue';
import useLoading from './loading';

export const CmProductOrder = {
  ORDER_CM_CREATIVE_DATE: '直近CM放送日',
  ORDER_CM_PRODUCT_NAME: '五十音順',
  ORDER_PRODUCT_LIST: '商品リスト順'
} as const;

type CmProductOrderType = typeof CmProductOrder;
export type Order = CmProductOrderType[keyof CmProductOrderType];

const convertOrder = (order: Order) => {
  switch (order) {
    case CmProductOrder.ORDER_CM_CREATIVE_DATE:
      return 'ORDER_CM_CREATIVE_DATE';
    case CmProductOrder.ORDER_CM_PRODUCT_NAME:
      return 'ORDER_CM_PRODUCT_NAME';
    case CmProductOrder.ORDER_PRODUCT_LIST:
      return 'ORDER_PRODUCT_LIST';
  }
};

interface UseCmProducts {
  cmProducts: Ref<OtherCmProduct[]>;
  endDate: Ref<Date | undefined>;
  isMainSelectNeeded: Ref<boolean>;
  mainCmProduct: Ref<OtherCmProduct | undefined>;
  order: Ref<Order>;
  cmSponsorId: Ref<number | undefined>;
  searchText: Ref<string>;
  selectedCmProductIds: Ref<number[]>;
  selectedCmProducts: Ref<OtherCmProduct[]>;
  selectedMainCmProductId: Ref<number | null>;
  startDate: Ref<Date | undefined>;
  decideDefaultMain: () => void;
  getCmProducts: (companyId: number) => Promise<void>;
  isLoadingCmProducts: Ref<boolean>;
  selectAllCmProducts: () => void;
  toggleAllCmProductSelect: () => void;
  unselectAllCmProducts: () => void;
}

export const useCmProducts = (): UseCmProducts => {
  const cmProducts = ref<OtherCmProduct[]>([]);
  const searchText = ref<string>('');
  const startDate = ref<Date | undefined>();
  const endDate = ref<Date | undefined>();
  const order = ref<Order>(CmProductOrder.ORDER_CM_CREATIVE_DATE);
  const cmSponsorId = ref<number | undefined>(undefined);
  const selectedCmProductIds = ref<number[]>([]);
  const selectedMainCmProductId = ref<number | null>(null);

  const _getCmProducts = async (companyId: number) => {
    const {
      data
    } = await CompanyApi.getCompaniesCompanyIdOtherCompanyCmProductsSearch(
      companyId,
      searchText.value ?? '',
      convertOrder(order.value),
      startDate.value ? format(startDate.value, DATE_FORMAT) : undefined,
      endDate.value ? format(endDate.value, DATE_FORMAT) : undefined,
      cmSponsorId.value ? cmSponsorId.value : undefined
    );
    cmProducts.value = data.list ?? [];
  };

  const [isLoadingCmProducts, getCmProducts] = useLoading(_getCmProducts);

  const selectedCmProducts = computed(() => {
    return cmProducts.value.filter(
      ({ id }) => !!id && selectedCmProductIds.value.includes(id)
    );
  });

  // 名寄せのベースとなるCM商品
  const mainCmProduct = computed(() => {
    return cmProducts.value.find(p => p.id === selectedMainCmProductId.value);
  });

  // 名寄せのベースとなる競合商品を決める
  const decideDefaultMain = () => {
    if (selectedCmProductIds.value.length >= 1) {
      selectedMainCmProductId.value = selectedCmProductIds.value[0];
    }
  };

  // 名寄せのベースとなる競合商品を選択する必要があるか
  const isMainSelectNeeded = computed(() => {
    return selectedCmProductIds.value.length >= 2;
  });

  const selectAllCmProducts = () => {
    selectedCmProductIds.value = cmProducts.value
      .map(({ id }) => id)
      .filter(id => id !== undefined) as number[];
  };

  const unselectAllCmProducts = () => {
    selectedCmProductIds.value = [];
  };

  const toggleAllCmProductSelect = () => {
    if (selectedCmProductIds.value.length > 0) {
      unselectAllCmProducts();
    } else {
      selectAllCmProducts();
    }
  };

  return {
    cmProducts,
    endDate,
    isMainSelectNeeded,
    mainCmProduct,
    order,
    cmSponsorId,
    selectedCmProductIds,
    selectedCmProducts,
    selectedMainCmProductId,
    searchText,
    startDate,
    decideDefaultMain,
    getCmProducts,
    isLoadingCmProducts,
    selectAllCmProducts,
    toggleAllCmProductSelect,
    unselectAllCmProducts
  };
};
