import { CompanyApi } from '@/api';
import {
  OtherCmProduct,
  CompetitiveProducts,
  CreateProductForm
} from '@/api/openapi';
import { Order, useCmProducts } from '@/composables/cmProducts';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';
import useLoading from '@/composables/loading';

const competitiveProductsHeaderIds = {
  NAME: 'NAME',
  COMPANY_NAME: 'COMPANY_NAME',
  EDIT: 'EDIT'
} as const;

const competitiveProductsDataListHeader = [
  {
    id: competitiveProductsHeaderIds.NAME,
    label: '競合商品／ブランド名'
  },
  {
    id: competitiveProductsHeaderIds.COMPANY_NAME,
    label: '競合企業名'
  },
  {
    id: competitiveProductsHeaderIds.EDIT,
    label: '編集',
    textAlign: 'center'
  }
];

const getCompetitiveProductsDataListItem = (products: CompetitiveProducts) => {
  const relatedCmProducts = products.relatedCmProducts;
  const hasRelatedCmProducts = relatedCmProducts
    ? relatedCmProducts.length > 0
    : false;
  return {
    [competitiveProductsHeaderIds.NAME]: {
      name: products.name,
      isEdited: hasRelatedCmProducts,
      relatedCmProducts: products.relatedCmProducts
    },
    [competitiveProductsHeaderIds.COMPANY_NAME]:
      products.competitiveCompanyName,
    [competitiveProductsHeaderIds.EDIT]: {
      id: products.id
    }
  };
};

interface UseCompetitiveProductsList {
  cmProducts: Ref<OtherCmProduct[]>;
  mainCmProduct: Ref<OtherCmProduct | undefined>;
  competitiveProductsDataListHeader: typeof competitiveProductsDataListHeader;
  competitiveProductsDataListItems: ComputedRef<
    ReturnType<typeof getCompetitiveProductsDataListItem>[]
  >;
  competitiveProductsHeaderIds: typeof competitiveProductsHeaderIds;
  competitiveProductsList: Ref<CompetitiveProducts[]>;
  competitiveProductName: Ref<string>;
  endDate: Ref<Date | undefined>;
  isCreateDisabled: Ref<boolean>;
  isMainSelectNeeded: Ref<boolean>;
  searchText: Ref<string>;
  selectedCmProductIds: Ref<number[]>;
  selectedCmProducts: Ref<OtherCmProduct[]>;
  selectedMainCmProductId: Ref<number | null>;
  startDate: Ref<Date | undefined>;
  order: Ref<Order>;
  cmSponsorId: Ref<number | undefined>;
  isLoadingCmProducts: Ref<boolean>;
  isSearching: Ref<boolean>;
  getCompetitiveProductsList: (companyId: number) => Promise<void>;
  getCmProducts: (companyId: number) => Promise<void>;
  searchCmProducts: (companyId?: number) => Promise<void>;
  decideDefaultMain: () => void;
  postCompetitiveCompanyProducts: (companyId: number) => Promise<void>;
  isPostingProducts: Ref<boolean>;
  selectAllCmProducts: () => void;
  toggleAllCmProductSelect: () => void;
  unselectAllCmProducts: () => void;
  editCompetitiveProductName: Ref<string>;
  editCompetitiveProduct: Ref<CompetitiveProducts | undefined>;
  selectedRemoveEditCmProductIds: Ref<number[]>;
  addCmProduct: Ref<OtherCmProduct[]>;
  editSearchCmProducts: Ref<OtherCmProduct[]>;
  isSaveDisabled: Ref<boolean>;
  setEditCompetitiveProductId: (productId: number) => void;
  selectAllRemoveEditCmProducts: () => void;
  moveCmProducts: (productId: number) => void;
  deleteCmProduct: (productId: number) => void;
}

export const useCompetitiveProductsList = (): UseCompetitiveProductsList => {
  const competitiveProductsList = ref<CompetitiveProducts[]>([]);
  const competitiveProductName = ref('');
  const editCompetitiveProductName = ref('');
  const editCompetitiveProduct = ref<CompetitiveProducts>();
  const editCompetitiveProductId = ref<number | null>(null);
  const selectedRemoveEditCmProductIds = ref<number[]>([]);
  const addCmProduct = ref<OtherCmProduct[]>([]);
  const editSearchCmProducts = ref<OtherCmProduct[]>([]);
  const isProductNameEdit = ref(false);

  const {
    cmProducts,
    endDate,
    isMainSelectNeeded,
    mainCmProduct,
    order,
    cmSponsorId,
    searchText,
    selectedCmProductIds,
    selectedCmProducts,
    selectedMainCmProductId,
    startDate,
    decideDefaultMain,
    getCmProducts,
    isLoadingCmProducts,
    selectAllCmProducts,
    toggleAllCmProductSelect,
    unselectAllCmProducts
  } = useCmProducts();

  const getCompetitiveProductsList = async (companyId: number) => {
    const { data } = await CompanyApi.getCompaniesCompanyIdCompetitiveProducts(
      companyId
    );
    competitiveProductsList.value = data.list ?? [];
    setEditCompetitiveProduct();
  };

  const competitiveProductsDataListItems = computed(() => {
    return competitiveProductsList.value.map(companyProducts =>
      getCompetitiveProductsDataListItem(companyProducts)
    );
  });

  // 登録される競合商品名
  const postedCompetitiveProductName = computed(() => {
    if (competitiveProductName.value !== '') {
      return competitiveProductName.value;
    } else {
      if (mainCmProduct.value) {
        return mainCmProduct.value.cmProductName;
      } else {
        return '';
      }
    }
  });

  const isCreateDisabled = computed(() => {
    return postedCompetitiveProductName.value === '';
  });

  // 競合商品登録
  const _postCompetitiveCompanyProducts = async (companyId: number) => {
    // const relatedCmProductIds = selectedCmProductIds.value.map(id => ({
    //   id,
    //   isMain: id === selectedMainCmProductId.value
    // }));
    const createProductForm: CreateProductForm = {
      productName: postedCompetitiveProductName.value ?? '',
      // relatedCmProductIds,
      isCompetitive: true,
      affiliatedCompanyId: companyId
    };
    const res = await CompanyApi.postCompaniesCompanyIdProducts(
      companyId,
      createProductForm
    );
    if (!(200 <= res.status && res.status < 300)) {
      throw new Error('');
    }
  };

  const [isPostingProducts, postCompetitiveCompanyProducts] = useLoading(
    _postCompetitiveCompanyProducts
  );

  const _searchCmProducts = async (companyId?: number) => {
    if (!companyId) return;
    await getCmProducts(companyId);
  };

  const [isSearching, searchCmProducts] = useLoading(_searchCmProducts);

  const setEditCompetitiveProductId = (productId: number) => {
    editCompetitiveProductId.value = productId;
  };
  const setEditCompetitiveProduct = () => {
    editCompetitiveProduct.value = competitiveProductsList.value.find(
      p => p.id === editCompetitiveProductId.value
    );
  };

  const selectAllRemoveEditCmProducts = () => {
    const relatedCmProducts =
      editCompetitiveProduct.value?.relatedCmProducts ?? [];
    selectedRemoveEditCmProductIds.value = relatedCmProducts
      .map(({ id }) => id)
      .filter(id => id !== undefined) as number[];
  };

  const moveCmProducts = (productId: number) => {
    const product = editSearchCmProducts.value.find(p => p.id === productId);
    if (product) {
      addCmProduct.value.push(product); // CM商品一覧に追加
      // 検索結果一覧から削除
      editSearchCmProducts.value = editSearchCmProducts.value.filter(
        product => product.id !== productId
      );
    }
  };

  const deleteCmProduct = (productId: number) => {
    const product = addCmProduct.value.find(p => p.id === productId);
    // 追加されたCM商品一覧から削除
    addCmProduct.value = addCmProduct.value.filter(
      product => product.id !== productId
    );
    // 検索結果一覧に追加
    if (product) {
      editSearchCmProducts.value.push(product);
    }
  };

  const isSaveDisabled = computed(() => {
    return (
      addCmProduct.value.length <= 0 &&
      selectedRemoveEditCmProductIds.value.length <= 0 &&
      !isProductNameEdit.value
    );
  });

  watch(
    () => editCompetitiveProduct.value,
    product => {
      editCompetitiveProductName.value =
        product?.name ?? product?.mainCmProduct?.name ?? '';
    }
  );

  watch(
    () => cmProducts.value,
    cmProducts => {
      const mainCmProduct = editCompetitiveProduct.value?.mainCmProduct;
      const relatedCmProducts =
        editCompetitiveProduct.value?.relatedCmProducts ?? [];
      if (cmProducts.length > 0) {
        // 検索結果一覧から競合商品と名寄せ対象CM商品一覧の重複を除外する
        editSearchCmProducts.value = cmProducts.filter(
          product =>
            mainCmProduct?.id !== product.id &&
            relatedCmProducts.findIndex(({ id }) => id === product.id) == -1 &&
            addCmProduct.value.findIndex(({ id }) => id === product.id) == -1
        );
      } else {
        editSearchCmProducts.value = [];
      }
    }
  );

  watch(
    () => editCompetitiveProductName.value,
    productName => {
      const firstName =
        // 競合名が初期値から変更されたか判定
        editCompetitiveProduct.value?.name ??
        editCompetitiveProduct.value?.mainCmProduct?.name ??
        '';
      isProductNameEdit.value = productName !== firstName && productName !== '';
    }
  );

  return {
    cmProducts,
    mainCmProduct,
    competitiveProductsDataListHeader,
    competitiveProductsDataListItems,
    competitiveProductsHeaderIds,
    competitiveProductsList,
    competitiveProductName,
    endDate,
    isMainSelectNeeded,
    isCreateDisabled,
    isSearching,
    isLoadingCmProducts,
    order,
    cmSponsorId,
    searchText,
    selectedCmProductIds,
    selectedCmProducts,
    selectedMainCmProductId,
    startDate,
    decideDefaultMain,
    getCompetitiveProductsList,
    getCmProducts,
    searchCmProducts,
    postCompetitiveCompanyProducts,
    isPostingProducts,
    selectAllCmProducts,
    toggleAllCmProductSelect,
    unselectAllCmProducts,
    selectedRemoveEditCmProductIds,
    editCompetitiveProductName,
    editCompetitiveProduct,
    editSearchCmProducts,
    addCmProduct,
    isSaveDisabled,
    setEditCompetitiveProductId,
    selectAllRemoveEditCmProducts,
    moveCmProducts,
    deleteCmProduct
  };
};
