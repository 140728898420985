import { ref, Ref } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { ProductApi } from '@/api';
import {
  ProductDetail,
  ProductDetailUsedProjects,
  ProductDetailUsedProjectsFeatureNameEnum,
  WorkspaceFeatureOptionTypeEnum
} from '@/api/openapi';

import { ROUTE_NAMES } from '@/router';
import { REACH_ANALYSIS_ROUTES } from '@/router/reachanalysis';
import { PLANNING_ROUTES } from '@/router/planning';
import { CAMPAIGN_ROUTES } from '@/router/campaign';
import { CMSIS_ROUTES } from '@/router/cmsis';
import { useUserInfoStore } from '@/store/userInfo';
import useLoading from '@/composables/loading';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';

interface Product {
  isLoading: Ref<boolean>;
  productDetail: Ref<ProductDetail | null>;
  reload(): Promise<void>;
  convertFeatureName(
    featureName: ProductDetailUsedProjectsFeatureNameEnum
  ): string;
  getLinkByUsedProject(usedProject: UsedProjects): unknown;
  canAccessProject(
    route: RouteLocationNormalizedLoaded,
    usedProject: UsedProjects
  ): boolean;
}

// APIからはEnumでくるため、DataTableのフィルタ用に表示用プロパティを追加する
export interface UsedProjects extends ProductDetailUsedProjects {
  displayFeatureName: string;
}

export const HEADERS: {
  id: string;
  label: string;
  class: string;
}[] = [
  {
    id: 'cmProduct',
    label: 'CM商品',
    class: 'p-sortable-column--md auto'
  },
  {
    id: 'cmSponsor',
    label: '企業',
    class: 'p-sortable-column--sponsor'
  },
  {
    id: 'grp',
    label: '直近3か月のGRP',
    class: 'p-sortable-column--xsh end'
  },
  {
    id: 'broadcastDate',
    label: '直近CM放送日',
    class: 'p-sortable-column--xsh center'
  }
];

export const HEADERS_PRJ: {
  id: string;
  label: string;
  class: string;
}[] = [
  {
    id: 'displayFeatureName',
    label: '機能',
    class: 'p-sortable-column--feature-name'
  },
  {
    id: 'projectName',
    label: 'プロジェクト名',
    class: 'p-sortable-column--md auto'
  }
];

export const useProductDetail = (props: {
  productId: number;
  isTvalProduct: boolean;
  affiliatedCompanyId: number | null;
  companyId: number;
}): Product => {
  const productDetail = ref<ProductDetail | null>(null);
  const [isLoading, fetch] = useLoading(_fetch);

  const reload = async (): Promise<void> => {
    const productData = await fetch(props);
    productDetail.value = productData;
  };

  const convertFeatureName = (
    featureName: ProductDetailUsedProjectsFeatureNameEnum
  ): string => {
    switch (featureName) {
      case ProductDetailUsedProjectsFeatureNameEnum.ReachAnalysis:
        return 'リーチ分析Classic';
      case ProductDetailUsedProjectsFeatureNameEnum.ReachAnalysis2:
        return 'リーチ分析';
      case ProductDetailUsedProjectsFeatureNameEnum.Tro:
        return 'タイムCMプランナー';
      case ProductDetailUsedProjectsFeatureNameEnum.CmSalesImpact:
        return '売上効果分析';
      case ProductDetailUsedProjectsFeatureNameEnum.CmSalesImpactSimulator:
        return '売上効果シミュレーター';
      default:
        return '';
    }
  };

  const getLinkByUsedProject = (usedProject: UsedProjects) => {
    const projectId = usedProject.projectId.toString();
    switch (usedProject.featureName) {
      case ProductDetailUsedProjectsFeatureNameEnum.ReachAnalysis:
        return {
          name: CAMPAIGN_ROUTES.graph,
          params: { projectId }
        };
      case ProductDetailUsedProjectsFeatureNameEnum.ReachAnalysis2:
        return {
          name: REACH_ANALYSIS_ROUTES.result,
          params: { projectId }
        };
      case ProductDetailUsedProjectsFeatureNameEnum.Tro:
        return {
          name: PLANNING_ROUTES.result,
          params: { projectId }
        };
      case ProductDetailUsedProjectsFeatureNameEnum.CmSalesImpact:
        // CMSIのみ特殊でパラメータがproductIdだが、バックエンドで考慮されているのでそのまま渡す
        return {
          name: CAMPAIGN_ROUTES.cmsiProduct,
          params: { productId: projectId }
        };
      case ProductDetailUsedProjectsFeatureNameEnum.CmSalesImpactSimulator:
        return {
          name: CMSIS_ROUTES.result,
          params: { projectId }
        };
      default:
        return {
          name: ROUTE_NAMES.notFound,
          params: { backTop: 'true' }
        };
    }
  };

  const canAccessProject = (
    route: RouteLocationNormalizedLoaded,
    usedProject: UsedProjects
  ): boolean => {
    // リーチ分析、リーチ分析Classicは有料オプションではないため、常にアクセス可能
    if (
      usedProject.featureName ===
        ProductDetailUsedProjectsFeatureNameEnum.ReachAnalysis ||
      usedProject.featureName ===
        ProductDetailUsedProjectsFeatureNameEnum.ReachAnalysis2
    ) {
      return true;
    }

    const userInfoStore = useUserInfoStore();
    const type = convertFeatureNameToFeatureOptionTypeEnum(
      usedProject.featureName
    );
    const workspace = userInfoStore.currentWorkspaceFromRoute(route);
    const featureOption = workspace?.featureOptions.find(v => v.type === type);
    const status =
      userInfoStore.getFeatureOptionStatus(route, type) &&
      featureOption?.companyIds?.includes(
        Number(route.params.companyId as string)
      );

    return process.env.NODE_ENV !== 'local' && !status ? false : true;
  };

  const convertFeatureNameToFeatureOptionTypeEnum = (
    featureName: ProductDetailUsedProjectsFeatureNameEnum
  ): WorkspaceFeatureOptionTypeEnum => {
    switch (featureName) {
      case ProductDetailUsedProjectsFeatureNameEnum.Tro:
        return WorkspaceFeatureOptionTypeEnum.Tro;
      case ProductDetailUsedProjectsFeatureNameEnum.CmSalesImpact:
        return WorkspaceFeatureOptionTypeEnum.CmSalesImpact;
      case ProductDetailUsedProjectsFeatureNameEnum.CmSalesImpactSimulator:
        return WorkspaceFeatureOptionTypeEnum.CmSalesImpactSimulator;
      default:
        return WorkspaceFeatureOptionTypeEnum.Tro;
    }
  };

  return {
    isLoading,
    productDetail,
    reload,
    convertFeatureName,
    getLinkByUsedProject,
    canAccessProject
  };
};

const _fetch = async (props: {
  productId: number;
  isTvalProduct: boolean;
  affiliatedCompanyId: number | null;
  companyId: number;
}): Promise<ProductDetail> => {
  try {
    if (props.affiliatedCompanyId) {
      const res = await ProductApi.getProductsProductIdDetail(
        props.productId,
        props.isTvalProduct,
        props.affiliatedCompanyId,
        props.companyId
      );
      return res.data;
    } else {
      return {} as ProductDetail;
    }
  } catch (e) {
    if (e.status === httpCode.forbidden) {
      toast({
        title: '商品詳細を検索する権限がありません',
        message: e.message,
        variant: 'error'
      });
    } else if (e.status === httpCode.timeout) {
      toast({
        title: '商品詳細を検索がタイムアウトしました',
        message: e.message,
        variant: 'error'
      });
    } else {
      handleError(e);
    }
    return {} as ProductDetail;
  }
};
