import { Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  JoinWorkspaceAccountPrivilegeEnum,
  JoinWorkspacePaymentTypeEnum
} from '@/api/openapi';
import { useUserInfoStore } from '@/store/userInfo';

interface Authority {
  isFreeWorkspace: Ref<boolean>;
  isPaidWorkspace: Ref<boolean>;
  isRestricted: Ref<boolean>;
}

export const useAuthority = (): Authority => {
  const isFreeWorkspace = ref(false);
  const isPaidWorkspace = ref(false);
  const isRestricted = ref(false);

  const route = useRoute();
  const userInfoStore = useUserInfoStore();
  const currentWorkspace = userInfoStore.currentWorkspaceFromRoute(route);
  isFreeWorkspace.value =
    currentWorkspace?.paymentType === JoinWorkspacePaymentTypeEnum.Free;
  isPaidWorkspace.value =
    currentWorkspace?.paymentType === JoinWorkspacePaymentTypeEnum.Paid;
  isRestricted.value =
    currentWorkspace?.accountPrivilege ===
    JoinWorkspaceAccountPrivilegeEnum.Restricted;

  return {
    isFreeWorkspace,
    isPaidWorkspace,
    isRestricted
  };
};
